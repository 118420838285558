<script setup>
  import VideoHeading from "~/components/Header/VideoHeading.vue";
  import ClientReviews from "~/components/ClientReviews.vue";
  import {homeQuery} from "~/composables/GraphQlQueries";
  import {useGQlData} from "~/composables/GraphQlHelper";
  import {useSeoData} from "~/composables/SeoHelper";
  import Sections from "~/components/Sections.vue";
  import Collage from "~/components/Collage.vue";
  import Categories from "~/components/Categories.vue";

  const data = await useGQlData(homeQuery);

  useSeoData(data?.page?.seo);
</script>

<template>
    <VideoHeading :title="data?.page?.homeTitle?.title"
                  :url="data?.page?.videoHeader?.videoHeader?.url"/>
    <div id="page-container" class="mb-5"></div>
    <div class="homepage-div">
      <Categories title="Our Homes" subtitle="FOR BLOCKS OF ALL SHAPES AND SIZES"/>
      <Collage :is-desc-html="true" :items="data?.page?.bannerImage?.bannerImage"/>
      <Sections :items="data?.page?.sections?.sections"/>
      <ClientReviews/>
    </div>
</template>
