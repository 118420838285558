<script setup lang="ts">
  const props = defineProps<{
    url: string;
    title: string;
  }>();
</script>
<template>
  <div class="w-100 bg-dark d-flex align-items-center justify-content-center" style="height: 100vh;">
    <video id="landing-video"
           preload="none"
           height="250" width="250"
           loop muted playsinline autoplay
           class="z-1 position-absolute w-100 bg-dark object-fit-cover" style="top: 0 !important; height: 100vh;">
      <source type="video/mp4" :src="props.url">
    </video>
    <div class="bg-transparent z-2 position-absolute w-100 h-100 object-fit-cover"></div>
    <h1 class="text-white welcome-label heading z-3 p-3">{{props.title}}</h1>
    <div class="position-absolute top-100 start-50 translate-middle z-2" id="scroll-next">
      <button id="btn-scroll"
              aria-label="Browse"
              class="bg-transparent border-0 p-0 animated bounce slower infinite">
        <NuxtImg loading="lazy"
                 alt="Browse"
                 quality="80"
                 height="30" width="60"
                 src="/arrow-white.svg"
                 id="header-img-arrow"/>
      </button>
    </div>
  </div>
</template>
